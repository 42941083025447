const baseUrl = process.env.REACT_APP_BASE_URL_API;

export const fetchDataFromBack = async (method, endpoint, body = null) => {
  const requestOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + apiToken,

    },
    credentials: 'include', // Incluir las cookies en la solicitud
  };

  if (body !== null) {
    requestOptions.body = JSON.stringify(body);
  }

  const response = await fetch(baseUrl + endpoint, requestOptions);

  try {
    return await response.json();
  } catch (error) {
    throw error;
  }
};

