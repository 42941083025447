import { createBrowserRouter } from 'react-router-dom';
import Root from './components/Root';
import Home from './components/pages/home/Home';
import FormView from './components/pages/qrform/FormView';
import TaskPointView from './components/pages/showTaskPoint/TaskPointView';
import PoseidonCallback from './components/PoseidonCallback';
import WarehouseView from './components/pages/warehouse/WarehouseView';

// Crear un enrutador de navegador utilizando la función createBrowserRouter de react-router-dom
const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/qr-services',
                element: <FormView />,
            },
            {
                path: '/qr-warehouses',
                element: <WarehouseView />,
            },

        ],
    },
    {
        path: '/showTaskPoint/:base64',
        element: <TaskPointView />,
    },
    {
        path: '/login/poseidon-callback',
        element: <PoseidonCallback />,
    },
]);

export default router;
