import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { RouterProvider } from 'react-router';
import router from './router';
import theme from './theme';

function App() {
    return (
        <ThemeProvider theme={theme} >
            <RouterProvider router={router} />
        </ThemeProvider>
    );
}

export default App;
