import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, LinearProgress, Typography } from '@mui/material';
import { fetchDataFromBack } from '../../../requests';
import LogoDimoba from '../../../assets/dimobaServiciosLogo';
import LogoGrupoControl from '../../../assets/grupoControlLogo';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import headIcon from '../../../assets/favicon.ico'
import { HeadProvider, Link, Meta, Title } from 'react-head';

const ShowTaskPoint = () => {
    const { base64 } = useParams();

    const [taskDetails, setTaskDetails] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingClient, setIsLoadingClient] = useState(true);
    const [isLoadingService, setIsLoadingService] = useState(true);
    const [isLoadingRound, setIsLoadingRound] = useState(true);
    const [isLoadingRoundPoint, setIsLoadingRoundPoint] = useState(true);
    const [isLoadingTasks, setIsLoadingTasks] = useState(true);


    const [companyCode, setCompanyCode] = useState("");
    const [contractCode, setContractCode] = useState("");
    const [serviceCode, setServiceCode] = useState("");
    const [roundCode, setRoundCode] = useState("");
    const [pointCode, setPointCode] = useState("");
    const [taskPointCodes, setTaskPointCodes] = useState("");
    const [formattedText, setFormattedText] = useState("");

    const [tasks, setTasks] = useState([]);

    //effect que controla las peticiones para obtener la informacion necesaria a partir de los codes/id que vienen en el base64
    useEffect(() => {
        const fetchData = async () => {
            try {
                const parts = atob(base64).split('_');
                setCompanyCode(parts[0]);

                const details = [
                    { label: 'Cliente', handler: handleCustomerName, param: parts[1] },
                    { label: 'Servicio', handler: handleServiceDescription, param: parts[2] },
                    { label: 'Ronda', handler: handleRoundDescription, param: parts[3] },
                    { label: 'Punto de ronda', handler: handleRoundPointDescription, param: parts[4] },
                ];
                details.map(async (detail) => detail.handler(detail.param))

                handleTaskPoints(parts[5]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    //Forma el body para la peticion de getTasks
    useEffect(() => {
        if (!isLoading) {
            const body = {
                "company_code": companyCode || "",
                "customer_contract_code": contractCode || "",
                "customer_contract_service_code": serviceCode || "",
                "ronda_cod": roundCode || "",
                "punto_cod": pointCode || "",
                "tareap_codtarea": taskPointCodes.tareap_codtarea || [],
            };

            handleTasks(body)
        }

    }, [isLoading]);

    useEffect(() => {
        // Check if taskDetails has the expected length
        if (taskDetails.length === 4) {
            var firstPart = "";
            var secondPart = "";

            // Use forEach instead of map for side-effect
            taskDetails.forEach((detail) => {
                if (detail.name != null) {
                    firstPart = detail.name;
                }
                if (detail.description != null) {
                    secondPart = detail.description;
                }
            });

            // Concatenate firstPart and secondPart
            const result = `${firstPart}, ${secondPart}`;

            // Update the state with the formatted text
            setFormattedText(result);
        }
    }, [taskDetails]);

    //Comprueba si ha terminado decargar todos las peticiones
    useEffect(() => {
        if (!isLoadingClient && !isLoadingService && !isLoadingRound && !isLoadingRoundPoint) {
            setIsLoading(false)
        }

    }, [isLoadingClient, isLoadingService, isLoadingRound, isLoadingRoundPoint]);

    //handler que separa los distintos puntos de tareas
    function handleTaskPoints(taskPoints) {
        const segments = taskPoints.split(".")
        const taskPointsArray = { tareap_codtarea: segments.map(String) };
        setTaskPointCodes(taskPointsArray);
    }

    //Handlers de las peticiones
    function handleCustomerName(customerCode) {
        fetchDataAndSetState('getCustomerName', 'customer_code', customerCode, setTaskDetails, 'name');
    }

    function handleServiceDescription(serviceId) {
        fetchDataAndSetState('getServiceDescription', 'service_id', serviceId, setTaskDetails, 'description');
    }

    function handleRoundDescription(roundId) {
        fetchDataAndSetState('getRoundDescription', 'round_id', roundId, setTaskDetails, 'ronda_desc');
    }

    function handleRoundPointDescription(pointId) {
        fetchDataAndSetState('getRoundPointDescription', 'punto_id', pointId, setTaskDetails, 'punto_desc');
    }

    //Handlers de tasks
    function handleTasks(body) {
        fetchDataAndSetState('getTasks', null, body, null, null);
    }

    //Funcion que realiza las peticiones
    function fetchDataAndSetState(endpoint, paramKey, paramValue, setState, property) {
        var body = {};
        if (paramKey !== null) {
            body = { [paramKey]: paramValue };
        } else {
            body = paramValue;
        }

        fetchDataFromBack('POST', endpoint, body)
            .then((response) => {
                if (property !== null) {
                    setState((prevDetails) => [...prevDetails, { [property]: response[0][property] }]);
                } else {
                    setTasks(response);
                    setIsLoadingTasks(false)
                }

                switch (endpoint) {
                    case 'getServiceDescription':
                        setContractCode(response[0]["customer_contract_code"]);
                        setServiceCode(response[0]["customer_contract_service_code"]);
                        setIsLoadingService(false);
                        break;

                    case 'getRoundDescription':
                        setRoundCode(response[0]["ronda_cod"]);
                        setIsLoadingRound(false);
                        break;

                    case 'getRoundPointDescription':
                        setPointCode(response[0]["punto_cod"]);
                        setIsLoadingRoundPoint(false);
                        break;

                    case 'getCustomerName':
                        setIsLoadingClient(false);
                        break;

                    default:
                        break;
                }
            })
    }

    return (
        <>
            <HeadProvider>
                <Title>QR Grupo Control</Title>
                <Link rel='icon' href={headIcon} />
                <Meta name='description' content='Page for Qr generation' />
            </HeadProvider>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#f6f6f6', }}>
                {isLoadingTasks ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '100%' }}>

                        <Typography variant="h3" sx={{ fontWeight: 'bold', paddingTop: '3em', paddingBottom: '1.5em' }}>
                            Cargando datos, espere por favor
                            <LinearProgress />

                        </Typography>

                    </Box>

                ) : (
                    <>
                        <Box sx={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ minWidth: '30%', backgroundColor: companyCode === '01' ? '#9B2743' : "#0047BA" }}>
                                {companyCode === '01' ? <LogoGrupoControl /> : <LogoDimoba />}
                            </Box>
                        </Box>
                        <Box sx={{ padding: '2em', minHeight: '100%' }}>
                            <Box
                                sx={{
                                    minWidth: '50%'
                                }}
                            >
                                {
                                    <Box
                                        sx={{
                                            paddingTop: '0em',
                                            paddingLeft: '3em',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', m: 0 }}>
                                            {
                                                formattedText
                                            }
                                        </Typography>

                                    </Box>
                                }
                            </Box>
                            <Box>
                                {tasks.length !== 0 ? (
                                    tasks.map((task, index) => (
                                        <>
                                            <Box
                                                key={index + task.tareapc_descripcion}
                                                sx={{
                                                    paddingTop: index === "0" + task.tareapc_descripcion ? '0em' : '1.5em',
                                                    paddingLeft: '3em',
                                                    display: 'flex',
                                                    alignItems: 'right',
                                                }}
                                            >
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', m: 0 }}>
                                                    {"Tarea " + (index + 1) + ": "}
                                                </Typography>
                                                <Typography variant="h6" sx={{ ml: 1 }}>
                                                    {task.tareapc_descripcion}
                                                </Typography>
                                            </Box>
                                            <Box
                                                key={index + task.vssper_nom + task.vssper_apell1 + task.vssper_apell2}
                                                sx={{
                                                    paddingLeft: '3em',
                                                    display: 'flex',
                                                    alignItems: 'right',
                                                }}
                                            >
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', m: 0 }}>
                                                    {"Trabajador: "}
                                                </Typography>
                                                <Typography variant="h6" sx={{ ml: 1 }}>
                                                    {task.vssper_nom + " " + task.vssper_apell1 + " " + task.vssper_apell2}
                                                </Typography>
                                            </Box>
                                            <Box
                                                key={index + task.tareapc_fecha}
                                                sx={{
                                                    paddingLeft: '3em',
                                                    display: 'flex',
                                                    alignItems: 'right',
                                                }}
                                            >
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', m: 0 }}>
                                                    {"Fecha: "}
                                                </Typography>
                                                <Typography variant="h6" sx={{ ml: 1 }}>
                                                    {task.tareapc_fecha}
                                                </Typography>
                                            </Box>
                                            <Box
                                                key={index + task.tareapc_checked}
                                                sx={{
                                                    paddingLeft: '3em',
                                                    display: 'flex',
                                                    alignItems: 'right',
                                                }}
                                            >
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', m: 0 }}>
                                                    {"Estado: "}
                                                </Typography>
                                                <Typography variant="h6" sx={{ ml: 1 }}>
                                                    {task.tareapc_checked === 0 ? (
                                                        <CancelIcon color="grey" />
                                                    ) : (
                                                        <CheckCircleIcon color="success" />
                                                    )}
                                                </Typography>
                                            </Box>
                                        </>
                                    ))
                                ) : (
                                    <Box
                                        sx={{
                                            paddingTop: '1.5em',
                                            paddingLeft: '3em',
                                            display: 'flex',
                                            alignItems: 'right',
                                        }}
                                    >
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', ml: 0 }}>
                                            No hay registros de esta tarea
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                    </>
                )}
            </Box>
        </>
    );
}

export default ShowTaskPoint;
