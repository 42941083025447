import React, { useEffect, useState } from 'react';
import { HeadProvider, Link as LinkHead, Meta, Title } from 'react-head'
import headIcon from '../assets/favicon.ico'
import { useCookies } from 'react-cookie';
import { LinearProgress, Typography } from '@mui/material';

function PoseidonCallback() {
    const [hasToken, setHasToken] = useState(false);
    const [cookie, setCookie] = useCookies(['poseidon_token']);

    useEffect(() => {
        const url = window.location.href;
        const urlObj = new URL(url);
        const token = urlObj.searchParams.get('token') ?? "";

        if (token !== "") {
            setCookie("poseidon_token", token, { path: '/' });
            setHasToken(true);
        }

    }, []);

    return (
        <>
            <HeadProvider>
                <Title>QR Grupo Control</Title>
                <LinkHead rel='icon' href={headIcon} />
                <Meta name='description' content='Page for Qr generation' />
            </HeadProvider>
            <Typography variant="h3" align="center" sx={{ marginTop: '2em' }}>
                Cargando, espere
                <LinearProgress />
            </Typography>            {
                hasToken && window.location.replace(process.env.REACT_APP_BASE_URL)
            }
        </>
    );
}

export default PoseidonCallback;
