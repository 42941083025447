import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RoundPointForm from "./RoundPointForm";
import QrImage from "./QrImage";

const FormView = () => {
    const [formSend, setFormSend] = useState(false);
    const [response, setResponse] = useState("");
    const [company_code, setCompany_code] = useState("");
    const [base64, setBase64] = useState("");

    const handleResponseChange = (newResponse) => {
        if (newResponse.roundPoint != null) {
            const company_code = newResponse.company.company.company_code
            setCompany_code(company_code)
            const customer_code = newResponse.customer.customer_code
            const service_id = newResponse.service.id
            const round_id = newResponse.round.id
            const roundPoint_id = newResponse.roundPoint.id

            const taskPoints = handleTaskPoints(newResponse.taskPoint)

            const txt_vars = company_code + "_" + customer_code + "_" + service_id + "_" + round_id + "_" + roundPoint_id + "_" + taskPoints
            setBase64(btoa(txt_vars))
            setResponse(process.env.REACT_APP_BASE_URL + "showTaskPoint/" + btoa(txt_vars));
        } else {
            setResponse("")
        }
    };

    function handleTaskPoints(taskPoints) {

        let response = "";

        taskPoints.forEach((taskPoint, index) => {
            response += taskPoint.tareap_codtarea;
            if (index < taskPoints.length - 1) {
                response += ".";
            }
        });

        return response
    }

    useEffect(() => {
        if (response.length !== 0) {
            setFormSend(true)
        } else {
            setFormSend(false)
        }
    }, [response]);



    return (
        <Box sx={{ backgroundColor: '#f6f6f6', paddingBottom: '8em' }}>

            <Typography
                variant="h5"
                component="h5"
                sx={{
                    paddingTop: '1em',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }}
            >
                Formulario de generación de QR servicios
            </Typography>
            {formSend ? (
                <QrImage
                    txtToEncode={response}
                    company_code={company_code}
                    base64={base64}
                />
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                    <Typography sx={{ paddingTop: '2em', paddingBottom: '2em' }}>
                        Completa el formulario para obtener tu código QR.
                    </Typography>
                </Box>
            )}
            <>
                <RoundPointForm
                    onResponseChange={handleResponseChange}
                />
            </>

        </Box>
    );

}

export default FormView;
