import { Box, Button, Checkbox, TextField, TextareaAutosize, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';



const QrImage = ({ txtToEncode, company_code, base64 }) => {
    const [qrCodeUrl, setQRCodeUrl] = useState('');
    const [commentPDF, setCommentPDF] = useState(false);
    const [haveTitlePDF, setHaveTitlePDF] = useState(false);
    const [txt, setTxt] = useState("");
    const [title, setTitle] = useState("");
    const baseUrl = process.env.REACT_APP_BASE_URL_API;


    useEffect(() => {
        const generateQRCode = async () => {
            const apiUrl = process.env.REACT_APP_BASE_URL_QR_WEB_API+`size=150&text=${encodeURIComponent(txtToEncode)}`;

            try {
                const response = await fetch(apiUrl);

                if (response.ok) {
                    const imageUrl = URL.createObjectURL(await response.blob());
                    setQRCodeUrl(imageUrl);
                } else {
                    console.error('Error al generar el código QR');
                }
            } catch (error) {
                console.error('Error de red', error);
            }
        };

        generateQRCode();
    }, [txtToEncode]);

    //Petitions depends of how interact the user with the selects in the form
    //It get trigger by changes on the father select, and the son change arround it
    const handleChangeCheckBox = () => {
        setCommentPDF(commentPDF ? false : true)
    };

    const handleChangeCheckBoxTitle = () => {
        setHaveTitlePDF(haveTitlePDF ? false : true)
    };

    const handleTextChange = (event) => {
        const nuevoTexto = event.target.value;
        setTxt(nuevoTexto);
    };
    const handleTitleChange = (event) => {
        const nuevoTexto = event.target.value;
        setTitle(nuevoTexto);
    };

    const handleButton = () => {
        const apiUrl = baseUrl + `getPdf?url=${encodeURIComponent(txtToEncode)}&text=${encodeURIComponent(txt)}&title=${encodeURIComponent(title)}&company_code=${encodeURIComponent(company_code)}`;

        // Redirigir el navegador a la URL para descargar el PDF
        window.location.href = apiUrl;
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography sx={{ paddingBottom: '2em' }}>
                        ¡Aquí esta tu codigo QR!
                    </Typography>
                    {qrCodeUrl && <img src={qrCodeUrl} alt="Código QR" />}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '40%', textAlign: 'center' }}>
                    <Button variant="contained" sx={{ alignSelf: 'flex-start' }} onClick={handleButton}>
                        <Typography sx={{ paddingRight: '1em' }}>
                            Descargar
                        </Typography>
                        <PictureAsPdfIcon />
                    </Button>
                    <Typography sx={{
                        paddingTop: '1em',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Checkbox
                            onChange={handleChangeCheckBoxTitle}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        Añadir titulo al PDF
                    </Typography>

                    {haveTitlePDF ?
                        <TextField
                            style={{
                                minWidth: '100%',
                                width: 'auto',
                            }}
                            type="text"
                            placeholder="Añade aquí tu título..."
                            value={title}
                            onChange={handleTitleChange}
                        />
                        : null
                    }
                    <Typography sx={{
                        paddingTop: '1em',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Checkbox
                            onChange={handleChangeCheckBox}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        Texto a mostrar junto a QR en el PDF
                    </Typography>

                    {commentPDF ?
                        <TextareaAutosize
                            style={{
                                minWidth: '100%',
                                width: 'auto',
                            }}
                            minRows={5}
                            placeholder="Añade aquí tu texto..."
                            value={txt}
                            onChange={handleTextChange}
                        />
                        : null
                    }
                </Box>
            </Box>


        </>
    );
};

export default QrImage;
