import { Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import QrCodeIcon from '@mui/icons-material/QrCode';


const WarehouseView = ({ }) => {
    const [qrCodeUrl, setQRCodeUrl] = useState('');
    const [text, setText] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL_API;

    const handleQRButton = async () => {
        const apiUrl =  process.env.REACT_APP_BASE_URL_QR_WEB_API+`size=150&text=${encodeURIComponent(text)}`;

        if (text.trim() !== '') {
            try {
                const response = await fetch(apiUrl);

                if (response.ok) {
                    const imageUrl = URL.createObjectURL(await response.blob());
                    setQRCodeUrl(imageUrl);
                } else {
                    console.error('Error al generar el código QR');
                }
            } catch (error) {
                console.error('Error de red', error);
            }
        } else {
            setShowNotification(true);
        }
    };

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handlePdfButton = () => {
        const apiUrl = baseUrl + `getPdf?url=${encodeURIComponent(text)}&text=${encodeURIComponent("")}&title=${encodeURIComponent("")}&company_code=${encodeURIComponent('02')}`;

        // Redirigir el navegador a la URL para descargar el PDF
        window.location.href = apiUrl;
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                            paddingTop: '1em',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            paddingBottom: '2em'
                        }}
                    >
                        Generación de QR almacenes
                    </Typography>
                    <Typography variant='h5' sx={{ paddingBottom: '0.5em' }}>
                        Introduce en la casilla de texto el contenido del QR.
                    </Typography>
                    {qrCodeUrl &&
                        <img src={qrCodeUrl} alt="Código QR" />
                    }
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '40%', textAlign: 'center' }}>
                    <TextField
                        style={{
                            minWidth: '100%',
                            width: 'auto',
                        }}
                        type="text"
                        placeholder="Añade aquí tu texto..."
                        value={text}
                        onChange={handleTextChange}
                        multiline={false}
                    />

                    <Box
                        sx={{ paddingTop: '2em' }}>
                    </Box>

                    <Button variant="contained" sx={{ alignSelf: 'flex-start', marginRight: '2em' }} onClick={handleQRButton}>
                        <Typography sx={{ paddingRight: '1em' }}>
                            Generar QR
                        </Typography>
                        <QrCodeIcon />
                    </Button>

                    <Button variant="contained" sx={{ alignSelf: 'flex-start' }} onClick={handlePdfButton}>
                        <Typography sx={{ paddingRight: '1em' }}>
                            Descargar
                        </Typography>
                        <PictureAsPdfIcon />
                    </Button>
                    <Typography sx={{ paddingTop: '2em' }}>
                        Recuerde volver a generar el QR si cambia el texto.
                    </Typography>
                </Box>
            </Box>
            {showNotification &&
                <Snackbar
                    open={showNotification}
                    autoHideDuration={3000}
                    onClose={
                        () => {
                            setShowNotification(false)
                        }
                    }
                    message="No se puede generar el QR vacío."
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    style={{ paddingBottom: '2em' }}
                />
            }
        </>
    );
};

export default WarehouseView;
